import { findIndex, propEq } from "ramda";

export const getUserName = (userInfo = {}) => {
  if (userInfo === null) {
    return "";
  }
  if (userInfo.name) {
    return userInfo.name;
  }
  if (userInfo.firstName && userInfo.lastName) {
    if (userInfo.firstName === userInfo.lastName) {
      return `${userInfo.firstName}`;
    }
    return `${userInfo.firstName} ${userInfo.lastName}`;
  }
  if (userInfo.firstName) {
    return userInfo.firstName;
  }
  if (userInfo.lastName) {
    return userInfo.lastName;
  }
  return "";
};

export const getAvatarName = (userInfo = {}) => {
  return getUserName(userInfo).slice(0, 1);
};

export const getEmailUserName = (userInfo = {}) => {
  if (userInfo.email) {
    return userInfo.email.split("@")[0];
  }
  return "";
};

export const passInputType = (openEyes) => {
  return openEyes ? "text" : "password";
};

export const renderArrowDirectionIcons = (type) => {
  if (type > 0) {
    return "North";
  }
  if (type === 0) {
    return "ArrowRight";
  }
  return "South";
};

export const mailNotExistedInfoMessage = (
  addCsvUploadData,
  mailNotExistedInfo,
) => {
  let result = "";
  mailNotExistedInfo.details.forEach((item) => {
    const csvIndex = findIndex(propEq("email", item))(addCsvUploadData) + 2;
    if (result === "") {
      result += `A${csvIndex}`;
    } else {
      result += `, A${csvIndex}`;
    }
  });
  return result;
};
