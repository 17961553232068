import context from "@components/context";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { getUserName } from "@utils/parse-utils";
import { filter } from "ramda";
import React, {
  createRef,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import * as S from "./SearchInput.module.scss";

/**
 * @params  {*}
 * defaultKeyword: default search input value
 * placeholder: search input placeholder
 * suggestion: show suggestion list
 * searchHistory: post & delete history policy
 * autoComplete: show auto complete list
 * AutoCompleteList: AutoCompleteList Component
 * SuggestionList: this component will includes [...historyList,...trendingList]
 * handleSearch(searchKeyword): handle search action
 * postSearchHistory: post search history
 * deleteSearchHistory: delete search history
 * getAutoCompleteList: autoComplete Resource,
 * getSearchHistory: searchHistory Resource,
 * getTrendingList: trending Resource,
 */
const SearchInput = ({
  forwardedRef,
  defaultKeyword = "",
  placeholder = "",
  suggestion = true,
  searchHistory = true,
  autoComplete = true,
  AutoCompleteList = null,
  SuggestionList = null,
  maxInputLength,
  handleSearch = () => {
    // This is intentional
  },
  handleCleanSearch = () => {
    // This is intentional
  },
  postSearchHistory = async () => {
    // This is intentional
  },
  deleteSearchHistory = () => {
    // This is intentional
  },
  getAutoCompleteList = async () => {
    // This is intentional
  },
  getSearchHistory = async () => {
    // This is intentional
  },
  getTrendingList = async () => {
    // This is intentional
  },
}) => {
  const inputRef = createRef();
  const { loginStatus: rootStatus } = useContext(context);
  const { userLanguage } = rootStatus || {};
  const [currentKeyCode, setCurrentKeyCode] = useState(0);
  const [suggestionIndex, setSuggestionIndex] = useState(-1);
  const [suggestionArray, setSuggestionArray] = useState([]);
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [showKeyword, setShowKeyword] = useState(false);
  const [getTrendingDone, setGetTrendingDone] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isKeywordInput, setIsKeywordInput] = useState(false);
  const [showCleanSearchButton, setShowCleanSearchButton] = useState(false);
  const [autoCompleteTotalCount, setAutoCompleteTotalCount] = useState(0);
  const selectedArray = isKeywordInput ? autoCompleteList : suggestionArray;
  const showSuggestionList = showSuggestion && !isKeywordInput && suggestion;
  const showAutoCompleteList =
    isFocus && isKeywordInput && showKeyword && autoComplete;

  const isClose = (n) => n.classList && n.classList[0] === "close";

  const plusSuggestionIndex = (index) => {
    if (index === selectedArray.length - 1) {
      return 0;
    }
    return index + 1;
  };

  const reduceSuggestionIndex = (index) => {
    if (index === 0) {
      return selectedArray.length - 1;
    }
    return index - 1;
  };

  const getSearchKeyword = (inputComponentRef) =>
    inputComponentRef?.current?.value?.trim() || "";

  const recordSearchHistory = async () => {
    if (rootStatus?.token && searchHistory) {
      await postSearchHistory(getSearchKeyword(inputRef));
    }
  };

  const searchAction = (index = null) => {
    setShowSuggestion(false);
    setShowKeyword(false);
    if (searchHistory) {
      recordSearchHistory();
    }
    if (inputRef.current.value) {
      inputRef.current.value = inputRef.current.value.trim();
    }
    handleSearch(
      selectedArray[index] ||
        selectedArray[suggestionIndex] ||
        getSearchKeyword(inputRef),
    );
  };

  const getAutoCompleteListResource = async () => {
    const searchKeyword = getSearchKeyword(inputRef);
    if (searchKeyword) {
      try {
        const {
          data = [],
          error,
          totalCount,
        } = await getAutoCompleteList(searchKeyword);
        setAutoCompleteTotalCount(totalCount);
        if (error) {
          throw error;
        } else {
          const temp = [];
          data.forEach((item) => {
            temp.push({ text: item, type: "keyword" });
          });

          setAutoCompleteList([...temp]);
        }
      } catch (error) {
        console.error("getAutoCompleteListResource", error);
      }
    }
  };

  const getSearchSource = async () => {
    try {
      const histories = [];
      if (rootStatus?.token && searchHistory) {
        const { data: searchHistoryData, error: searchHistoryError } =
          await getSearchHistory();
        if (searchHistoryError) {
          throw searchHistoryError;
        }
        if (searchHistoryData) {
          searchHistoryData.forEach((item) => {
            histories.push({ text: item, type: "history" });
          });
        }
      }
      if (suggestion) {
        const { data: trendingList = [], error: trendingListError } =
          await getTrendingList({ language: userLanguage });
        if (trendingListError) {
          throw trendingListError;
        }
        const temp = [];
        let flag = 0;
        trendingList.forEach((item) => {
          if (flag <= 1) {
            temp.push({ text: item, type: "trending" });
            flag += 1;
          }
        });
        setSuggestionArray([...histories, ...temp]);
      }
    } catch (error) {
      console.error("getSearchSource", error);
    }
  };

  const handleKeyUp = async (event) => {
    setCurrentKeyCode(event.keyCode);
    if (event.keyCode === 40) {
      // keypress: down
      setShowSuggestion(true);
      setSuggestionIndex(plusSuggestionIndex(suggestionIndex));
    } else if (event.keyCode === 38) {
      // keypress: up
      setShowSuggestion(true);
      setSuggestionIndex(reduceSuggestionIndex(suggestionIndex));
    } else if (event.keyCode === 13) {
      event.preventDefault();
    } else {
      setShowSuggestion(true);
    }
  };

  const handleBlur = () => {
    setSuggestionIndex(-1);
  };

  const handleFocus = () => {
    setShowSuggestion(true);
    setIsFocus(true);
  };

  const handleKeywordChange = async (event) => {
    const { value } = event.target;
    if (!isKeywordInput) {
      setSuggestionIndex(-1);
    }
    setIsKeywordInput(value.trim());
    setShowKeyword(value.trim());
    if (autoComplete) {
      await getAutoCompleteListResource();
    }
  };

  const handleMouseOver = (index) => {
    setSuggestionIndex(index);
  };

  const focusTarget = (index) => {
    const { text } = selectedArray[index];
    const { name } = text;
    inputRef.current.value =
      name || (typeof text !== "string" && getUserName(text)) || text;
  };
  const selectTarget = (index) => {
    const { event } = window;
    // The path property of Event objects is non-standard. The standard equivalent is composedPath, which is a method.
    const path = event.path || (event.composedPath && event.composedPath());
    if (filter(isClose, path).length === 0) {
      focusTarget(index);
      searchAction(index);
      setIsFocus(false);
    }
  };

  const controlSuggestion = (evt) => {
    const event = evt || window.event;
    if (event) {
      // The path property of Event objects is non-standard. The standard equivalent is composedPath, which is a method.
      const path = event.path || (event.composedPath && event.composedPath());
      const isSearchInput =
        typeof event.target?.className === "string" &&
        event.target.className.indexOf("searchInput") >= 0;
      setShowSuggestion(isSearchInput || filter(isClose, path).length !== 0);
      setShowKeyword(isSearchInput);
    }
  };

  useEffect(() => {
    if (
      suggestionIndex >= 0 &&
      suggestionIndex <= selectedArray.length - 1 &&
      (currentKeyCode === 38 || currentKeyCode === 40)
    ) {
      focusTarget(suggestionIndex);
    }
    setShowCleanSearchButton(inputRef.current?.value);
  }, [
    isKeywordInput,
    suggestionArray,
    autoCompleteList,
    suggestionIndex,
    inputRef,
    currentKeyCode,
  ]);

  useEffect(() => {
    window.addEventListener("click", controlSuggestion);
    return () => {
      window.removeEventListener("click", controlSuggestion);
    };
  }, [showSuggestion]);

  useEffect(() => {
    if (showKeyword && !getTrendingDone) {
      setGetTrendingDone(true);
      if (autoComplete) getSearchSource();
    }
  }, [showKeyword, getTrendingDone]);

  useEffect(() => {
    if (autoComplete) getSearchSource();
  }, []);

  const cleanSearchHandler = () => {
    inputRef.current.value = "";
    handleCleanSearch();
    searchAction();
  };

  const formSubmit = (event) => {
    event.preventDefault();
    searchAction();
  };

  useImperativeHandle(forwardedRef, () => ({
    search: () => searchAction(),
  }));

  const renderSearchResultList = (
    isShowSuggestionList,
    isShowAutoCompleteList,
  ) => {
    if (isShowSuggestionList) {
      return (
        <SuggestionList
          searchHistory={searchHistory}
          suggestionArray={suggestionArray}
          handleMouseOver={handleMouseOver}
          selectTarget={selectTarget}
          suggestionIndex={suggestionIndex}
          deleteSearchHistory={deleteSearchHistory}
          setSuggestionArray={setSuggestionArray}
        />
      );
    }
    if (isShowAutoCompleteList) {
      return (
        <AutoCompleteList
          autoCompleteArray={autoCompleteList}
          handleMouseOver={handleMouseOver}
          selectTarget={selectTarget}
          suggestionIndex={suggestionIndex}
          inputRef={inputRef}
          autoCompleteTotalCount={autoCompleteTotalCount}
          setAutoCompleteList={setAutoCompleteList}
        />
      );
    }
    return null;
  };
  return (
    <div className={S.searchInputWrapper}>
      <form autoComplete="off" onSubmit={formSubmit} className={S.searchForm}>
        <IconButton onClick={searchAction} className={S.searchIcon}>
          <Icon>search</Icon>
        </IconButton>
        <input
          id="searchInput"
          ref={inputRef}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleKeywordChange}
          className={S.searchInput}
          defaultValue={defaultKeyword}
          placeholder={placeholder}
          autoComplete="off"
          role="searchInput"
          maxLength={maxInputLength}
        />
        {showCleanSearchButton && (
          <IconButton className={S.icon_close} onClick={cleanSearchHandler}>
            <Icon>close</Icon>
          </IconButton>
        )}
      </form>
      {renderSearchResultList(showSuggestionList, showAutoCompleteList)}
    </div>
  );
};

const SearchInputForwarded = forwardRef((props, ref) => (
  <SearchInput {...props} forwardedRef={ref} />
));
SearchInputForwarded.displayName = "SearchInput";
export default SearchInputForwarded;
